// import { v4 as uuid } from "uuid"

let inventory = [
  //YOUTHS
  {
    categories: ["new arrivals", "youths"],
    name: "Youth N95 Mask - Pink",
    sku: "sku_HFbys11GTPf9jm",
    price: 10,
    description: "Pink",
    images: ["../images/products/y_pink_f.jpg","../images/products/y_pink_s.jpg"],
    currentInventory: 10,
  },
  {
    categories: ["new arrivals", "youths"],
    name: "Youth N95 Mask - Blue",
    sku: "sku_HFbyeb5q1trw0a",
    price: 10,
    description: "Light Blue",
    images: ["../images/products/y_blue_s.jpg"],
    currentInventory: 10,
  },
  {
    categories: ["new arrivals", "youths"],
    name: "Youth N95 Mask - Tan",
    sku: "sku_HFbynhnIylbAEU",
    price: 10,
    description: "Tan",
    images: ["../images/products/y_tan_f.jpg", "../images/products/y_tan_s.jpg"],
    currentInventory: 10,
  },
  {
    categories: ["new arrivals", "youths"],
    name: "Youth N95 Mask - White",
    sku: "sku_HFbyR7WuSCrtSo",
    price: 10,
    description: "White",
    images: ["../images/products/y_white_f.jpg", "../images/products/y_white_s.jpg"],
    currentInventory: 10,
  },
  {
    categories: ["new arrivals", "youths"],
    name: "Youth N95 Mask - Dark Blue",
    sku: "sku_HFbyJEI1knDubs",
    price: 10,
    description: "Dark Blue",
    images: ["../images/products/y_dblue_f.jpg", "../images/products/y_dblue_s.jpg"],
    currentInventory: 10,
  },
  {
    categories: ["new arrivals", "youths"],
    name: "Youth N95 Mask - Green",
    sku: "sku_HFbOwbdi8pJ3hj",
    price: 10,
    description: "Green",
    images: ["../images/products/y_green_f.jpg", "../images/products/y_green_s.jpg"],
    currentInventory: 10,
  },
  //ADULTS
  {
    categories: ["new arrivals", "adults"],
    name: "Adult N95 Mask - Grey",
    sku: "sku_HFbtvVyHI3wlZP",
    price: 15,
    description: "Grey",
    images: ["../images/products/a_grey_f.jpg", "../images/products/a_grey_s.jpg"], //https://picsum.photos/200
    currentInventory: 12,
  },
  {
    categories: ["new arrivals", "adults"],
    name: "Adult N95 Mask - Red",
    sku: "sku_HFbs17S54oM1mp",
    price: 15,
    description: "Red",
    images: ["../images/products/a_red_f.jpg", "../images/products/a_red_s.jpg"], //https://picsum.photos/200
    currentInventory: 12,
  },
  {
    categories: ["new arrivals", "adults"],
    name: "Adult N95 Mask - Black",
    sku: "sku_HFbub6wjSa6KCZ",
    price: 15,
    description: "Black",
    images: ["../images/products/a_black_f.jpg", "../images/products/a_black_s.jpg"], //https://picsum.photos/200
    currentInventory: 12,
  },
  {
    categories: ["new arrivals", "adults"],
    name: "Adult N95 Mask - Navy",
    sku: "sku_HFbsjRDEbs1oRP",
    price: 15,
    description: "Navy",
    images: ["../images/products/a_navy_f.jpg", "../images/products/a_navy_s.jpg"], //https://picsum.photos/200
    currentInventory: 12,
  },
  {
    categories: ["new arrivals", "adults"],
    name: "Adult N95 Mask - Blue",
    sku: "sku_HFbtj8ooe2fIa2",
    price: 15,
    description: "Blue Check",
    images: ["../images/products/a_blue_s.jpg"], //https://picsum.photos/200
    currentInventory: 11,
  },
  //ACCESSORIES 
  {
    categories: ["new arrivals", "accessories"],
    name: "PM2.5 Filter",
    sku: "sku_HFbSzgJnkoOpk1",
    price: 1.5,
    description: "Reusable charcoal filter, 2.5M rated (filters particles larger than 2.5 microns)",
    images: ["../images/products/f_insert.jpg"],
    currentInventory: 990,
  },
  //BUNDLES
  // {
  //   categories: ["new arrivals", "bundles"],
  //   name: "The Sidekick",
  //   price: 20,
  //   images: ["../images/products/y_blue_f.jpg", "../images/products/y_blue_s.jpg"],
  //   description: "Both of you",
  //   brand: "White Label",
  //   currentInventory: 20,
  // },
  {
    categories: ["bundles", "new arrivals", "accessories"],
    name: "5 Filter Pack!",
    sku: "sku_HFbX49cAmFNrJB",
    price: 6,
    images: ["../images/products/f_fan.jpg"],
    description: "5 Pack of PM2.5 Filters. About a 1-month supply with everyday use.",
    brand: "PM2.5",
    currentInventory: 50,
  },
  // {
  //   categories: ["bundles", "new arrivals", "adults"],
  //   name: "The Hat Trick",
  //   price: 25,
  //   images: ["../images/products/masks_3.png"],
  //   description: "Tré Day!",
  //   brand: "White Label",
  //   currentInventory: 10,
  // },
  // {
  //   categories: ["bundles", "new arrivals", "adults"],
  //   name: "The 4-Peat",
  //   price: 40,
  //   images: ["../images/products/masks_4.png"],
  //   description: "El Quattro!",
  //   brand: "White Label",
  //   currentInventory: 10,
  // },
]

// inventory.map(i => {
//   i.id = uuid()
//   return i
// })
if (process.env.NODE_ENV === "development") {
  inventory.push({
    categories: ["bundles", "new arrivals", "adults"],
    name: "The 4-Peat",
    sku: "sku_HFe7pUnT8OJNQ9",
    price: 40,
    images: ["../images/products/a_red_f.jpg", "../images/products/a_red_s.jpg"],
    description: "El Quattro!",
    brand: "White Label",
    currentInventory: 10,
  })
}

/** FACEBOOK SHAPE //

image_link, title, Description, id, link, price, condition, availability, brand, identifier_exists, inventory,	google_product_category
https://shop.kielbyrne.com/images/products/a_black_f.jpg, Adult N95 Washable Mask w/ Filter Pocket, Black, sku_HFbub6wjSa6KCZ, https://shop.kielbyrne.com/adult-n95-mask-black, 10, new, in stock, BLKLBL, true, 12, 8222
https://shop.kielbyrne.com/images/products/a_grey_f.jpg, Adult N95 Washable Mask w/ Filter Pocket, Grey, sku_HFbtvVyHI3wlZP, https://shop.kielbyrne.com/adult-n95-mask-grey, 10, new, in stock, BLKLBL, true, 2, 8222
https://shop.kielbyrne.com/images/products/a_blue_f.jpg, Adult N95 Washable Mask w/ Filter Pocket, Blue Check, sku_HFbtj8ooe2fIa2, https://shop.kielbyrne.com/adult-n95-mask-blue, 10, new, in stock, BLKLBL, true, 12, 8222
https://shop.kielbyrne.com/images/products/a_navy_f.jpg, Adult N95 Washable Mask w/ Filter Pocket, Navy, sku_HFbsjRDEbs1oRP, https://shop.kielbyrne.com/adult-n95-mask-navy, 10, new, in stock, BLKLBL, true, 12, 8222
https://shop.kielbyrne.com/images/products/a_red_f.jpg, Adult N95 Washable Mask w/ Filter Pocket, Red, sku_HFbs17S54oM1mp, https://shop.kielbyrne.com/adult-n95-mask-red, 10, new, in stock, BLKLBL, true, 12, 8222
https://shop.kielbyrne.com/images/products/pm25.png, P2.5 Dust Filter, Dust Filter, sku_HFbSzgJnkoOpk1, https://shop.kielbyrne.com/pm25-filter, 1.50, new, in stock, BLKLBL, true, 1000, 1816
https://shop.kielbyrne.com/images/products/y_pink_f.jpg, Youth N95 Washable Mask w/ Filter Pocket, Pink, sku_HFbys11GTPf9jm, https://shop.kielbyrne.com/youth-n95-mask-pink, 10, new, in stock, BLKLBL, true, 10, 8222
https://shop.kielbyrne.com/images/products/y_blue_f.jpg, Youth N95 Washable Mask w/ Filter Pocket, Blue, sku_HFbyeb5q1trw0a, https://shop.kielbyrne.com/youth-n95-mask-blue, 10, new, in stock, BLKLBL, true, 10, 8222
https://shop.kielbyrne.com/images/products/y_darkblue_f.jpg, Youth N95 Washable Mask w/ Filter Pocket, Dark Blue, sku_HFbyJEI1knDubs, https://shop.kielbyrne.com/youth-n95-mask-dark-blue, 10, new, in stock, BLKLBL, true, 10, 8222
https://shop.kielbyrne.com/images/products/y_white_f.jpg, Youth N95 Washable Mask w/ Filter Pocket, White, sku_HFbyR7WuSCrtSo, https://shop.kielbyrne.com/youth-n95-mask-white, 10, new, in stock, BLKLBL, true, 10, 8222
https://shop.kielbyrne.com/images/products/y_tan_f.jpg, Youth N95 Washable Mask w/ Filter Pocket, Tan, sku_HFbynhnIylbAEU, https://shop.kielbyrne.com/youth-n95-mask-tan, 10, new, in stock, BLKLBL, true, 10, 8222
https://shop.kielbyrne.com/images/products/y_green_f.jpg, Youth N95 Washable Mask w/ Filter Pocket, Green, sku_HFbOwbdi8pJ3hj, https://shop.kielbyrne.com/youth-n95-mask-green, 10, new, in stock, BLKLBL, true, 10, 8222


*
*/

export default inventory

