import React, { useState, useEffect } from "react"
import SignUp from "../components/formComponents/SignUp"
import ConfirmSignUp from "../components/formComponents/ConfirmSignUp"
import SignIn from "../components/formComponents/SignIn"
import Inventory from "../templates/Inventory"
import { doSignOut, doSignUp, doSignIn } from "../../utils/auth"
function Admin() {
  const [formState, setFormState] = useState("signUp")
  const [isAdmin, setIsAdmin] = useState(false)
  // const [data, setData] = useState(null)

  const toggleFormState = formState => {
    setFormState(formState)
  }
  const signUp = async form => {
    const { username, email, password } = form
    console.log({ username, email, password })
    // sign up
    doSignUp(email, password)
    setFormState("confirmSignUp")
  }
  const confirmSignUp = async form => {
    const { username, authcode } = form
    console.log({ username, authcode })
    // confirm sign up
    setFormState("signIn")
  }
  const signIn = async form => {
    const { username, password } = form;
    console.log({username, password})

    // signIn
    doSignIn(username, password)
    setFormState("signedIn")
    setIsAdmin(true)
  }
  const signOut = async () => {
    // sign out
    doSignOut()
    setFormState("signUp")
  }

  useEffect(() => {

  //   firebase
  //     .database()
  //     .ref("/inventory")
  //     .once("value")
  //     .then(snapshot => {
  //       setData(snapshot.val())
  //     })
  //   return () => {
  //     // cleanup
  //   }
  }, [])
  const renderForm = (formState, state) => {
    switch (formState) {
      case "signUp":
        return (
          <SignUp
            {...state}
            signUp={signUp}
            toggleFormState={toggleFormState}
          />
        )
      case "confirmSignUp":
        return <ConfirmSignUp {...state} confirmSignUp={confirmSignUp} />
      case "signIn":
        return (
          <SignIn
            {...state}
            signIn={signIn}
            toggleFormState={toggleFormState}
          />
        )
      case "signedIn":
        return isAdmin ? (
          <Inventory {...state} signOut={signOut} />
        ) : (
          <h3>Not An Admin</h3>
        )
      default:
        return null
    }
  }

  return (
    <div className="flex flex-col">
      <div className="max-w-fw flex flex-col">
        <div className="pt-10">
          <h1 className="text-5xl font-light">Admin Panel</h1>
        </div>
        {renderForm(formState)}
      </div>
    </div>
  )
}

export default Admin
