
//Actions
//Route
// import * as ROUTE from "./../Routes";

//Firebase Auth
import firebase from "gatsby-plugin-firebase";
// import { onceGetUser, uploadUser } from "./rtdb";

// const normalizeNumber = number => `+1${number.match(/\d/g).join('')}`;

export function doSignUp(email, password) {
  firebase.auth().createUserWithEmailAndPassword(email, password).catch(function(error) {
    // Handle Errors here.
    // const errorCode = error.code;
    const errorMessage = error.message
    // ...
    console.log(error.message)
    alert(errorMessage)
  });
}
export function doConfirmSignUp() {}
export function doSignIn(username, password) {
  //get email from username? 
  const email = username
  firebase.auth().signInWithEmailAndPassword(email, password).catch(function(error) {
    // Handle Errors here.
    // const errorCode = error.code;
    const errorMessage = error.message;
    // ...
    console.log(error.message)
    alert(errorMessage)
  });
}
export function doSignOut() {
  firebase.database.goOffline();
  firebase.auth.signOut();
    // type: ACTIONS.RESET_APP
    // type: ACTIONS.RESET_USERS
    // type: ACTIONS.RESET_FORMS
  // Router.push(ROUTES.LANDING);
}

// function* facebookSignIn() {
//   console.log("signing in with facebook")
//   const fbProvider = new firebase.auth.FacebookAuthProvider();

//   const res = yield firebase.auth
//     .signInWithPopup(fbProvider)
//     .then(result => {
//       console.log(result)
//       return result;
//     })
//     .catch(err => {
//       console.log(err);
//       return err;
//     });

//   return res;
// }

// function* googleSignIn() {
//   const googleProvider = new firebase.auth.GoogleAuthProvider();

//   const res = yield firebase.auth
//     .signInWithPopup(googleProvider)
//     .then(result => {
//       return result;
//     })
//     .catch(err => {
//       console.log(err);
//       return err;
//     });

//   return res;
// }

// function* emailSignIn({email,password}) {
//   // const emailProvider = new firebase.auth.createUserWithEmailAndPassword(email, password);

  
// //start of example

//   const actionCodeSettings = {
//     // URL you want to redirect back to. The domain (www.example.com) for this
//     // URL must be whitelisted in the Firebase Console.
//     url: 'https://www.example.com/finishSignUp?cartId=1234',
//     // This must be true.
//     handleCodeInApp: true,
//     // iOS: {
//     //   bundleId: 'com.example.ios'
//     // },
//     // android: {
//     //   packageName: 'com.example.android',
//     //   installApp: true,
//     //   minimumVersion: '12'
//     // },
//     dynamicLinkDomain: 'example.page.link'
//   };

//   const signInProvider = firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings)
//   .then(function() {
//     // The link was successfully sent. Inform the user.
//     // Save the email locally so you don't need to ask the user for it again
//     // if they open the link on the same device.
//     window.localStorage.setItem('emailForSignIn', email);
//   })
//   .catch(function(error) {
//     // Some error occurred, you can inspect the code: error.code
//   });




//   const res = yield firebase.auth
//     .signInWithPopup(emailProvider)
//     .then(result => {
//       return result;
//     })
//     .catch(err => {
//       console.log(err);
//       return err;
//     });
//   return res;
// }

// function* phoneSignIn({captchaConfirm, code}) {
//   //i have code, sign in user and set authuser to state.
//   const res = yield captchaConfirm.confirm(code).then(result => result)
//     .catch(function (error) {
//       console.error(error)
//       // ...
//     })
//   return res;
// }

// function* phoneVerify({phoneNum}) {
//   // console.log("running...")
//   //use recaptcha to send sms to phone, wait for code to signin 
//   let appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
//     'size': 'invisible', //invisible/normal
//     'callback': function(response) {
//       console.log('Captcha Solved...')
//     }
//   })
  
//   phoneNum = normalizeNumber(phoneNum)
//   console.log(phoneNum)
//   const confirmationResult = yield firebase.auth
//     .signInWithPhoneNumber(phoneNum, appVerifier)
//     .then(confirmationResult => confirmationResult)
//     .catch(error => error)

//     return
// }

//Saga Handler
// function* handleAuth(action) {
//   // console.log("handling auth...")
//   const { dispatch, filter, payload } = action;

//   let result;
  
//   let profileData;
//   if (result && result.additionalUserInfo.isNewUser) {
//     console.log(result.user)
//     const { displayName, email, phoneNumber, photoURL, uid, creationDate } = result.user;
//     const { profile, providerId } = result.additionalUserInfo;
//     // console.log(`Provider ID is ${providerId}`)

//     if (providerId === "facebook.com") {
//       profileData = {
//         ...profileData,
//         family_name: profile.last_name ? profile.last_name : "",
//         given_name: profile.first_name ? profile.first_name : "",
//         name: profile.name ? displayName : "",
//         avatar: profile.picture ? photoURL : ""
//       };
//     } else if (providerId === "google.com") {
//       profileData = {
//         ...profileData,
//         family_name: profile.family_name ? profile.family_name : "",
//         given_name: profile.given_name ? profile.given_name : "",
//         name: displayName ? displayName : "",
//         avatar: photoURL ? photoURL : "",
//         email: email ? email : "",
//       }
//     } 
    

//     profileData = {
//       appointments: 0,
//       appointmentsTotal: 0,
//       birthdate: false,
//       email: false,
//       favorites: {},
//       isBanned: false,
//       membership: "basic",
//       nextAppointmentDate: false,
//       phoneNumber,
//       uid,
//       referralNumber: payload ? normalizeNumber(payload.data.referralNum): "0001112222",
//       avatar: `https://robohash.org/${phoneNumber}.png?set=set2`,
//       ...profileData,
//     };

//   } else if (result && !result.additionalUserInfo.isNewUser) {
//     const { uid } = result.user;

//   }
// }



// get number
// call signInWithPhoneNumber with phoneNum and reCaptchaVerifier
// -> will send SMS
// prompt for verification code
// call confirm with verification code
// if success, user is logged in; if error, reset reCaptcha
